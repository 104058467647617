<template>
<div>
  <el-row>
    <el-col>
      <el-form :inline='true'>
        <el-form-item label="">
          <el-select style="width: 150px;" v-model="filters.contractId" placeholder="合同名称" filterable clearable>
            <el-option
                v-for="item in contractIdArray"
                :key="item.id"
                :label="item.customerName"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-input style="width: 150px;" v-model="filters.reportYear" @input="(e) => (filters.reportYear = utils.validForbid(e))" clearable
                    placeholder="年份"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select style="width: 150px;" v-model="filters.reportMonth" placeholder="月份" clearable>
            <el-option
                v-for="item in month"
                :key="item"
                :label="item"
                :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
<!-- 列表-->
  <el-table id="exportTab" :data="tableData" border height="700"
            v-loading="listLoading" ref="multipleTable" @current-change="currentChange"
            :highlight-current-row='true' :cell-style="rowClass">
    <el-table-column type='index' label="序号" width='60' align="center" :index='indexMethod'></el-table-column>
    <el-table-column prop="customerName" label="名称"  align="center"></el-table-column>
    <el-table-column prop="" label="结算时间（月）" width='145' align="center">
      <template slot-scope="scope">
        {{ scope.row.reportYear }} - {{ scope.row.reportMonth }}
      </template>
    </el-table-column>
    <el-table-column prop="totalProjectArea" width='145' label="总图审面积" align="center"></el-table-column>
    <el-table-column prop="contractAmount" width='145' label="年合同金额" align="center"></el-table-column>
    <el-table-column prop="actualAmountByMonth" width='145' label="月实际收入" align="center"></el-table-column>
    <el-table-column prop="actualAmountByYear" width='145'  label="年实际收入" align="center"></el-table-column>
    <el-table-column prop="belongPeriod" width='145' label="收入分类" align="center">
      <template slot-scope="scope">
        {{ '审图收入' }}
      </template>
    </el-table-column>
    <el-table-column prop="invoiceStatus" width='145' label="发票" align="center">
      <template slot-scope="scope">
        <el-button type="text" @click="onInvoice(scope.row)" :disabled="scope.row.invoiceCount === 0">
          {{ scope.row.invoiceCount }}
        </el-button>
      </template>
    </el-table-column>
    <el-table-column prop="projectCount" width='145' label="项目数" align="center">
      <template slot-scope="scope">
        <el-button type="text" :disabled="scope.row.projectCount === 0" @click="getProjectList(scope.row)">
          {{ scope.row.projectCount }}
        </el-button>
      </template>
    </el-table-column>
  </el-table>
  <!--底部工具条-->
  <el-col :span="24" class="pageBar">
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                   :current-page="pages.pageIndex" :page-sizes="pages.pageArr" :page-size="pages.pageSize"
                   layout="total, sizes, prev, pager, next, jumper" :total="dataCount">
    </el-pagination>
  </el-col>
<!--  项目详情-->
  <el-dialog title="项目列表" :visible.sync="dialog.projectList.show" v-model="dialog.projectList.show"
             :close-on-click-modal="false" width='85.7%'>
    <el-table id='personal' :data="dialog.projectList.list" border v-loading="listLoading"
              ref="multipleTable" :highlight-current-row='true'>
      <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
      <el-table-column prop="projectNo" label="项目编号" align="center"></el-table-column>
      <el-table-column prop="areaName" width='160' label="（县）区" align="center"></el-table-column>
      <el-table-column prop="projectName" label="项目名称" align="center"></el-table-column>
      <el-table-column prop="contractNo" label="关联合同编号" align="center"></el-table-column>
      <el-table-column prop="projectContacts" label="项目联系人" align="center"></el-table-column>
      <el-table-column prop="projectPhone" label="联系方式" align="center"></el-table-column>
      <el-table-column prop="projectUnitPrice" label="项目单价（元）" align="center"></el-table-column>
      <el-table-column prop="projectArea" label="图审面积（平方米）" align="center"></el-table-column>
      <!-- <el-table-column prop="contractNo" width='160' label="项目金额（元）" align="center"></el-table-column> -->
      <el-table-column prop="ddIdProjectStatusName" label="项目状态" align="center">
        <template slot-scope='scope'>
          <el-tag v-if="scope.row.ddIdProjectStatus == 34">立项</el-tag>
          <el-tag v-if="scope.row.ddIdProjectStatus == 35">上传资料</el-tag>
          <el-tag v-if="scope.row.ddIdProjectStatus == 44">初审任务分配</el-tag>
          <el-tag v-if="scope.row.ddIdProjectStatus == 45">初审意见填写</el-tag>
          <el-tag v-if="scope.row.ddIdProjectStatus == 46">初审意见提交完成</el-tag>
          <el-tag v-if="scope.row.ddIdProjectStatus == 47">初审报告签章完成</el-tag>
          <el-tag v-if="scope.row.ddIdProjectStatus == 50">上传复核资料</el-tag>
          <el-tag v-if="scope.row.ddIdProjectStatus == 51">复核任务分配</el-tag>
          <el-tag v-if="scope.row.ddIdProjectStatus == 52">复核意见填写</el-tag>
          <el-tag v-if="scope.row.ddIdProjectStatus == 53">复核意见提交完成</el-tag>
          <el-tag v-if="scope.row.ddIdProjectStatus == 54">图审报告签章完成</el-tag>
          <el-tag v-if="scope.row.ddIdProjectStatus == 55">审结</el-tag>
          <el-tag v-if="scope.row.ddIdProjectStatus == 134">初审报告完成</el-tag>
          <el-tag v-if="scope.row.ddIdProjectStatus == 135">复核报告完成</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="conclusionTime" width='160' label="项目归档日期" align="center"></el-table-column>
      <el-table-column prop="verificationStatus" width='160' label="项目匹配状态" align="center">
        <template slot-scope='scope'>
          <el-tag v-if="scope.row.verificationStatus == 0" type="danger">未匹配</el-tag>
          <el-tag v-if="scope.row.verificationStatus == 1" type="success">部分匹配</el-tag>
          <el-tag v-if="scope.row.verificationStatus == 2">全匹配</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer" class="dialog-footer">
      <el-button @click.native="dialog.projectList.show = false">关闭</el-button>
    </div>
  </el-dialog>
<!--  发票详情-->
  <el-dialog title="发票列表" :visible.sync="dialog.invoice.show" v-model="dialog.invoice.show"
             :close-on-click-modal="false" width='80%'>
    <el-table
        :data="dialog.invoice.list"
        border
        style="width: 100%"
        v-loading="listLoading"
        ref="multipleTable"
        :highlight-current-row='true'
        :cell-style="rowClass"
    >
      <!--            @current-change='currentInvoiceChange'-->
      <el-table-column type='index' label="序号" width='60' align="center" ></el-table-column>
      <el-table-column prop="issuerType" label="开票类型" align="center" width='120' show-overflow-tooltip>
        <template slot-scope='scope'>
          <el-tag v-if="scope.row.issuerType == 0">个人</el-tag>
          <el-tag v-if="scope.row.issuerType == 1" type="success">企业</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="invoiceTpye" label="发票类型" width='120' align="center" show-overflow-tooltip>
        <template slot-scope='scope'>
          <el-tag v-if="scope.row.invoiceTpye == 0" type="success">全电专票</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="invoiceMoney" label="开票金额" width='120' align="center"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="invoiceTitle" label="发票抬头" width='120' align="center"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="invoiceFax" label="购方税号" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="externalPersonPhone" label="手机号" align="center"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="externalPersonEmail" label="邮箱" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="openTime" label="开票时间" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="invoiceStatus" label="收款匹配状态" align="center" show-overflow-tooltip>
        <template slot-scope='scope'>
          <el-tag v-if="scope.row.invoiceStatus == 0" type="danger">未匹配</el-tag>
          <el-tag v-if="scope.row.invoiceStatus == 1" type="success">部分匹配</el-tag>
          <el-tag v-if="scope.row.invoiceStatus == 2">全匹配</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer" class="dialog-footer">
      <el-button @click.native="dialog.invoice.show = false">关闭</el-button>
    </div>
  </el-dialog>
</div>
</template>

<script>
import {
  getReportList,
  getCompanyContractList,
  getAreaDictionaryList,
  getProjectListByIds,
  getInvoiceList, getBillList
} from '@/api/api'
import util from '../../../util/date'
import { getButtonList } from '@/promissionRouter'
import Toolbar from '@/components/Toolbar.vue'
import * as XLSX from 'xlsx'
import FileSaver from 'file-saver'


export default {
  components:{
    Toolbar,
  },
  data(){
    return{
      needData:undefined,
      dialog:{
        projectList:{
          show:false,
          list:undefined
        },
        invoice:{
          show:false,
          list: undefined,
        }
      },
      contractIdArray:[],//ID数组
      filters:{
        contractId:undefined,
        reportMonth:undefined,
        reportYear:undefined,
        provinceCode:undefined,
      },
      dataCount: undefined,
      tableData:undefined,//项目列表
      listLoading:false,
      buttonList: [],
      //关于分页的obj
      pages: {
        pageSize: 20,//默认每页条数
        pageArr: [10, 20, 30, 40],//默认每页条数选择
        pageIndex: 1, //默认进入页
        dataCount: 20, //默认总页数
      },
      utils:'',
      month:[1,2,3,4,5,6,7,8,9,10,11,12],//月份
      provinceArr: [],//省
      exportList: [],
      exportBoolean: false,
      exportName: [
        { header: '名称', property: 'customerName' },
        { header: '结算年', property: 'reportYear' },
        { header: '结算月', property: 'reportMonth' },
        { header: '总图审面积', property: 'totalProjectArea' },
        { header: '年合同金额', property: 'contractAmount' },
        { header: '月实际收入', property: 'actualAmountByMonth' },
        { header: '年实际收入', property: 'actualAmountByYear' },
        { header: '收入分类', property: 'belongPeriod' },
        { header: '发票', property: 'invoiceCount' },
        { header: '项目数', property: 'projectCount' },
      ],
      isExporting: false,
    }
  },
  created () {
    this.utils = util
    let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
    this.buttonList = getButtonList(this.$route.path, routers);
    this.getDataList();
    this.init();
  },
  methods:{
    getListData(){
      this.pages.pageIndex = 1
      this.getDataList()
    },
    async init(){
      //获取所有省份
      const initResult = await Promise.all([
        getAreaDictionaryList({ parentId: 1 }),
      ])
      const [provinceRes] = initResult;
      if (provinceRes.data.success) {
        this.provinceArr = provinceRes.data.response.reverse();
      }
      await this.contractIdList()
    },
    getDataList () {
      let params = {
        contractId:this.filters.contractId||undefined,//合同ID
        pageIndex:this.pages.pageIndex,//页码
        pageSize:this.pages.pageSize,//每页条数
        reportMonth:this.filters.reportMonth,//所属月
        reportYear:this.filters.reportYear,//所属年
      }
      getReportList(params).then(res => {
        if (res.data.success) {
          this.tableData= res.data.response.data;
          this.dataCount = res.data.response.dataCount;
        }
      })
    },
    async contractIdList  (){
      const result = await getCompanyContractList()
      const data = result.data
      if (data.success){
        const response = data.response;
        this.contractIdArray = response
      }
    },
    //项目列表
    getProjectList (row) {
      getProjectListByIds({ projectIds: row.projectIds }).then(res => {
        if (res.data.success) {
          this.dialog.projectList.list = res.data.response;
        }
      })
      this.dialog.projectList.show = true;
    },
    //发票列表
    onInvoice (row) {
      let params = {
        invoiceIds: row.invoiceIds,//发票ID组（逗号隔开）
        // invoiceStatus: 0,//发票匹配状态
        // invoiceTitle: '',//发票名称
        // openEndDate: '',//开票结束时间
        // openStartDate: '',//开票开始时间
        pageIndex: 1,//页码
        pageSize: 40,//每页条数
      }
      getInvoiceList(params).then(res => {
        let result = res.data;
        if (result.success) {
          const response = result.response
          const data = response.data
          this.dialog.invoice.list = data
        }
      })
      this.dialog.invoice.show = true;
    },
    //导出汇总
    async exportAll () {
      for (let i = 1; i < ((this.dataCount / this.pages.pageSize) < 1 ? 2 : +Math.ceil(this.dataCount / this.pages.pageSize))+1; i++) {
        console.log(i,'iiiiii')
        let params = {
          contractId: this.filters.contractId || undefined,//合同ID
          pageIndex: i,//页码
          pageSize: this.pages.pageSize,//每页条数
          reportMonth: this.filters.reportMonth,//所属月
          reportYear: this.filters.reportYear,//所属年
        }
        const res = await getReportList(params)
        var result = res.data.response.data
        console.log(result, 'res')
        if (result){
          result.forEach(item => {
            item['belongPeriod'] = '审图收入'
          })

          this.exportList.push(...result)
          setTimeout(() => {

          }, 100)
        }

      }
      console.log(this.exportList, 'exportList')
    },
    exportToExcel (dataArray, headersMapping) {
      // 从映射中提取自定义表头
      const headers = headersMapping.map(mapping => mapping.header);

      // 从数据中筛选并映射对应属性
      const filteredData = dataArray.map(obj =>
          headersMapping.reduce((acc, mapping) => {
            const { header, property } = mapping;
            if (Object.prototype.hasOwnProperty.call(obj, property)) {
              acc[header] = obj[property];
            }
            return acc;
          }, {})
      );

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(filteredData, { header: headers });

      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });

      const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const fileName = '应收款明细报表.xlsx';

      if (window.navigator.msSaveOrOpenBlob) {
        // For IE/Edge browsers
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        // For other browsers
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    //导出
    async exportListData () {

      if (this.isExporting) {
        console.log('导出操作仍在进行中...');
        this.$message.error('导出操作仍在进行中...')
        return; // 如果导出仍在进行中，则退出函数
      }
      this.isExporting = true; //（isExporting是一个用于控制按钮状态的变量）
      await this.exportAll()
      setTimeout(() => {
        this.exportToExcel(this.exportList, this.exportName)
        this.isExporting = false;
        this.exportList = []
      }, 0)

    },
    // 点击table某一行
    currentChange (val) {
      if (val) {
        this.needData = val
        console.log(val, '列表')
      }
    },
    callFunction (item) {
      this[item.func].apply(this, item);
    },
    // 分页
    handleCurrentChange (val) {
      this.pages.pageIndex = val;
      this.getDataList();
    },
    handleSizeChange (val) {
      this.pages.pageSize = val;
      this.getDataList();
    },
    rowClass ({ columnIndex }) {
      return 'padding:4px!important;'
    },
    // 表格数据分页条数累计
    indexMethod (index) {
      var currentPage = this.pages.pageIndex
      var pageSize = this.pages.pageSize
      return (index + 1) + (currentPage - 1) * pageSize
    },
  }
}
</script>

<style scoped>

</style>
